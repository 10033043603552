<template>
  <div class="container">
    <div v-if="!status_true" class="application">
      <div class="application-item">
        <div class="application-list">
          <h2 class="application-title">MUROJAAT HOLATINI <br>
            TEKSHIRISH</h2>
          <div class="application-link mt-5">
            <p>Murojaat ID raqami</p>
            <input v-model="form.revision_id" v-maska="'#####'" type="number">
            <p>Holatni tekshirish kodi</p>
            <input v-model="form.status_code" v-maska="'######'" type="number">
          </div>
          <div class="button-list">
            <a href="/" type="button" class="back-btn"><i class="lni-arrow-left" />&nbsp; Orqaga qaytish</a>
            <a class="next-btn" @click="check()">Tekshirish</a>
          </div>
        </div>
        <div class="application-list d-block">
          <img class="applicationImg" src="@/assets/images/img/ariza-qoldirish.png" width="500px" alt="">
        </div>
      </div>
    </div>
    <div v-else>
      <StatusMessage :status="status" />
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import StatusMessage from '../message/check-status-message.vue'
import axios from 'axios'
export default {
  components: { StatusMessage },
  data() {
    return {
      status: {},
      status_true: false,
      form: {
        revision_id: null,
        status_code: null
      }
    }
  },
  methods: {
    check() {
      if (!this.form.revision_id || !this.form.status_code) {
        ElMessage({
          type: 'error',
          message: "Maydonlarni to'ldiring"
        })
      } else {
        axios.post(`https://dmi.mehnat.uz/api/v1/getStatus`, this.form)
          .then((res) => {
            if (res.data.data && res.data.data.status) {
              this.status = res.data.data
              this.status_true = true
            } else {
              ElMessage({
                type: 'error',
                message: "Kodni tekshirib qayta kiritib ko'ring"
              })
            }
          })
          .catch(() => {
            ElMessage({
              type: 'error',
              message: "Kodni tekshirib qayta kiritib ko'ring"
            })
          })
      }
      // goToRoute('CheckStatusMessage')
    }
  }
}
</script>

<style>

</style>
